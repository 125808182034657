import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import dayjs from 'dayjs'
import { Button, DatePicker, Input, Select, Space, theme } from 'antd'
import type { ColumnsType } from 'antd/es/table'

import { CellFormatSum } from '@/components/CellFormatSum'
import { CusTable, TCusTableIns } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { useControllerRef } from '@/hooks/useAbortController'
import { DEFAULT_PAGE_SIZE, getDefaultPaginationProps } from '@/utils/pagination'
import { useDateRangePresets } from '@/utils/utils'
import { MerchantSelect } from '../../components/MerchantSelect'
import { DetailsDrawer } from '../Recharge'
import { getDisabledDateRangeFn, useDOCUMENT_TYPE_NAME_MAP, useEXPENSE_TYPE_NAME_MAP, useRule } from '../utils'
import { apiGetRecords } from './apis'
import { DeliveryDetailDrawer } from './DeliveryDetailDrawer'
import { FreightDetailDrawer } from './FreightDetailDrawer'
import { IRecord, IReqData, IRes } from './interface'
import { ProcessingDetailDrawer } from './ProcessingDetailDrawer'
import { popupSlot, useTYPE_NAME_MAP } from './utils'
import styles from './styles.module.less'

export const AccountFlow = () => {
  const { t } = useTranslation()
  const { token } = theme.useToken()
  const userRule = useRule()
  const dateRangePresets = useDateRangePresets()
  const documentTypeNameMap = useDOCUMENT_TYPE_NAME_MAP()
  const typeNameMap = useTYPE_NAME_MAP()
  const expenseTypeNameMap = useEXPENSE_TYPE_NAME_MAP()

  const [transactionTypes, seTtransactionTypes] = useState<IReqData['transactionTypes']>()
  const [expenseTypes, setExpenseTypes] = useState<IReqData['expenseTypes']>()
  const [transactionDateBegin, setTransactionDateBegin] = useState<IReqData['transactionDateBegin']>(() => {
    return dateRangePresets.last30days.value[0].format($F_YMD)
  })
  const [transactionDateEnd, setTransactionDateEnd] = useState<IReqData['transactionDateEnd']>(() => {
    return dateRangePresets.last30days.value[1].format($F_YMD)
  })
  const [documentId, setDocumentId] = useState<IReqData['documentId']>()
  const [merchantUserIds, setMerchantUserIds] = useState<IReqData['merchantUserIds']>()
  const [page, setPage] = useState<IReqData['page']>(1)
  const [size, setSize] = useState<IReqData['size']>(DEFAULT_PAGE_SIZE)

  const reqData: IReqData = {
    transactionTypes,
    expenseTypes,
    transactionDateBegin,
    transactionDateEnd,
    documentId,
    merchantUserIds,
    page,
    size,
  }
  const tableRef = useRef<TCusTableIns>(null)
  const controllerRef = useControllerRef()
  const {
    loading,
    data,
    params: [param],
    run: getList,
  } = useRequest<IRes, [IReqData]>(
    req => {
      controllerRef.current.abort()
      controllerRef.current = new AbortController()
      return apiGetRecords(req, controllerRef.current.signal)
    },
    {
      defaultParams: [reqData],
      onBefore: () => CusTable.scrollToTop(tableRef.current),
    },
  )

  const columns: ColumnsType<IRecord> = [
    {
      title: t('Transaction.jiao-yi-id'),
      render: (_v, { transactionId: v }) => v,
    },
    {
      title: t('2-common.shang-jia-ming-cheng'),
      hidden: userRule === 'customer',
      render: (_v, { merchantUserName: v }) => v,
    },
    {
      title: t('Transaction.dan-ju-id'),
      render: (_v, { documentId: v }) => v,
    },
    {
      title: t('Transaction.dan-ju-lei-xing'),
      render: (_v, { documentType: v }) => (v ? documentTypeNameMap[v] || v : '--'),
    },
    {
      title: t('Transaction.jiao-yi-fen-lei'),
      render: (_v, { transactionType: v }) => typeNameMap[v] || v,
    },
    {
      title: t('Transaction.fei-yong-lei-mu'),
      render: (_v, { expenseType: v }) => expenseTypeNameMap[v] || v,
    },
    {
      title: t('Transaction.jin-e'),
      width: 120,
      align: 'right',
      render: (_v, { amount: v }) => <CellFormatSum value={v} />,
    },
    {
      title: t('Transaction.sheng-yu-zong-yu-e'),
      width: 120,
      align: 'right',
      render: (_v, { remainingBalance: v }) => <CellFormatSum value={v} />,
    },
    {
      title: t('Transaction.jiao-yi-shi-jian'),
      render: (_v, { createdTime: v }) => v && dayjs(v).format($F_YMDHms),
    },
    {
      title: t('2-common.cao-zuo'),
      width: 100,
      render: (_v, record) => {
        const { transactionId, expenseType } = record
        if (expenseType === 2) return // TODO
        return (
          <Button
            type="link"
            onClick={() => {
              switch (expenseType) {
                case 1: {
                  DeliveryDetailDrawer.open({ transactionId })
                  break
                }
                case 4: {
                  const updatePopup = popupSlot.insert(
                    <DetailsDrawer mode="view" props={{ transactionId }} destroy={() => updatePopup(null)} />,
                  )
                  break
                }
                case 6: {
                  ProcessingDetailDrawer.open({ transactionId })
                  break
                }
                default: {
                  FreightDetailDrawer.open({ transactionId })
                  break
                }
              }
            }}
          >
            {t('2-common.xiang-qing')}
          </Button>
        )
      },
    },
  ]

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <div className={styles.header_filter}>
          <Space size={[16, 8]} wrap>
            <Space size={0}>
              {t('Transaction.jiao-yi-fen-lei')}:&nbsp;
              <Select
                style={{ width: 90 }}
                value={transactionTypes?.[0] ?? -1}
                onChange={val => {
                  const v = val === -1 ? undefined : [val]
                  seTtransactionTypes(v)
                  setPage(1)
                  getList({ ...reqData, transactionTypes: v, page: 1 })
                }}
              >
                <Select.Option value={-1}>{t('2-common.quan-bu')}</Select.Option>
                <Select.Option value={1}>{typeNameMap[1]}</Select.Option>
                <Select.Option value={2}>{typeNameMap[2]}</Select.Option>
              </Select>
            </Space>
            <Space size={0}>
              {t('Transaction.fei-yong-lei-mu')}:&nbsp;
              <Select
                style={{ minWidth: 110 }}
                listHeight={300}
                popupMatchSelectWidth={false}
                showSearch={false}
                allowClear
                mode="multiple"
                maxTagCount={1}
                maxTagTextLength={6}
                placeholder={<span style={{ color: token.colorTextBase }}>{t('2-common.quan-bu')}</span>}
                value={expenseTypes}
                onChange={val => {
                  const v = val.length > 0 ? val : undefined
                  setExpenseTypes(v)
                  setPage(1)
                  getList({ ...reqData, expenseTypes: v, page: 1 })
                }}
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9].map(v => ({
                  value: v,
                  label: expenseTypeNameMap[v] || v,
                }))}
              />
            </Space>
            {userRule === 'employee' && (
              <MerchantSelect
                style={{ width: 160 }}
                value={merchantUserIds?.[0]}
                onChange={val => {
                  const merchantUserIds = val ? [val] : undefined
                  setMerchantUserIds(merchantUserIds)
                  setPage(1)
                  getList({ ...reqData, merchantUserIds, page: 1 })
                }}
              />
            )}
            <Space size={0}>
              {t('Transaction.jiao-yi-ri-qi')}:&nbsp;
              <DatePicker.RangePicker
                style={{ width: 215 }}
                allowClear={false}
                disabledDate={getDisabledDateRangeFn(6)}
                presets={[
                  dateRangePresets.today,
                  dateRangePresets.yesterday,
                  dateRangePresets.thisWeek,
                  dateRangePresets.lastWeek,
                  dateRangePresets.thisMonth,
                  dateRangePresets.lastMonth,
                  dateRangePresets.last7days,
                  dateRangePresets.last30days,
                  dateRangePresets.last6months,
                ]}
                value={[dayjs(transactionDateBegin), dayjs(transactionDateEnd)]}
                onChange={(_dates, [transactionDateBegin, transactionDateEnd]) => {
                  setTransactionDateBegin(transactionDateBegin)
                  setTransactionDateEnd(transactionDateEnd)
                  setPage(1)
                  getList({ ...reqData, transactionDateBegin, transactionDateEnd, page: 1 })
                }}
              />
            </Space>
            <Input.Search
              enterButton
              allowClear
              placeholder={t('Transaction.qing-shu-ru-dan-ju-id')}
              onChange={e => setDocumentId(e.target.value.trim() || undefined)}
              onSearch={(val, _e, info) => {
                val = val.trim()
                if (info?.source === 'clear' && !param?.documentId) return
                setPage(1)
                getList({ ...reqData, documentId: val || undefined, page: 1 })
              }}
            />
          </Space>
        </div>
        <div className={styles.header_action}></div>
      </div>

      <div className={styles.table}>
        <CusTable
          rowKey="id"
          loading={{ spinning: loading, delay: SPIN_DELAY }}
          dataSource={data?.records}
          columns={columns}
          ref={tableRef}
          verticalFlexLayout
          pagination={{
            ...getDefaultPaginationProps(data?.total || 0),
            current: page,
            pageSize: size,
            onChange: (page, size) => {
              setPage(page)
              setSize(size)
              getList({ ...reqData, page, size })
            },
          }}
        />
      </div>

      <popupSlot.Slot />
    </div>
  )
}
