import axios, { AxiosRequestConfig } from 'axios'

import { IDeliveryDetail, IFreightDetail, IProcessingDetail, IReqData, IRes } from './interface'
import './__mock__'

/** 获取主列表 */
export const apiGetRecords = async (params: IReqData, signal?: AxiosRequestConfig['signal']): Promise<IRes> => {
  return axios.get(`/linkhub_oms/user/account/flow`, { params, signal }).then(res => res.$data)
}

/** 获取详情：1-提货费; */
export const apiGetDeliveryDetail = async (transactionId: string): Promise<IDeliveryDetail> => {
  return axios.get(`/linkhub_oms/user/account/flow/detail/pickUpOrder/${transactionId}`).then(res => res.$data)
}

/** 获取详情：6-加工费; */
export const apiGetProcessingDetail = async (transactionId: string): Promise<IProcessingDetail> => {
  return axios.get(`/linkhub_oms/user/account/flow/detail/processing/${transactionId}`).then(res => res.$data)
}

/** 获取详情：3-操作费;5-运费;7-VAT手续费;8-VAT增值税;9-挂号费; */
export const apiGetFreightDetail = async (transactionId: string): Promise<IFreightDetail> => {
  return axios.get(`/linkhub_oms/user/account/flow/detail/freight/${transactionId}`).then(res => res.$data)
}
