import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import { Button, Descriptions, Divider, Drawer, Space } from 'antd'

import { CellFormatSum } from '@/components/CellFormatSum'
import { CusTable } from '@/components/CusTable'
import { Loading } from '@/components/Loading'
import { apiGetFreightDetail } from './apis'
import { popupSlot } from './utils'

const updatePopup = popupSlot.insert(null)

interface IFreightDetailDrawerProps {
  transactionId: string
}

/** 详情：5-运费; */
export const FreightDetailDrawer = Object.assign(
  ({ transactionId, destroy }: IFreightDetailDrawerProps & { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)

    const { data, loading } = useRequest(apiGetFreightDetail, { defaultParams: [transactionId] })

    return (
      <Drawer
        styles={{ body: { paddingTop: 0 } }}
        title={t('Transaction.yun-fei-xiang-qing')}
        width={777}
        open={open}
        onClose={onHide}
        afterOpenChange={afterOpenChange}
        extra={
          <Space>
            <Button onClick={onHide}>{t('2-common.guan-bi')}</Button>
          </Space>
        }
      >
        {loading ? (
          <Loading />
        ) : !data ? null : (
          <>
            <Descriptions
              style={{ marginTop: 24 }}
              size="small"
              bordered
              column={1}
              labelStyle={{ whiteSpace: 'nowrap' }}
              items={[
                {
                  label: t('Transaction.ding-dan-id'),
                  children: data.sysOrderId,
                },
                {
                  label: t('Transaction.shi-ji-zhong-liang'),
                  children: `${data.packageWeight ?? '--'}g`,
                },
                {
                  label: t('Order.gua-hao-fei'),
                  children: <CellFormatSum value={data.registrationFee} mode="inline" />,
                },
                {
                  label: t('Order.yun-fei'),
                  children: <CellFormatSum value={data.freight} mode="inline" />,
                },
                {
                  label: t('Transaction.cao-zuo-fei'),
                  children: <CellFormatSum value={data.operatingCharge} mode="inline" />,
                },
                {
                  label: t('Transaction.vat-shou-xu-fei'),
                  children: <CellFormatSum value={data.handlingFee} mode="inline" />,
                },
                {
                  label: t('Transaction.vat-shui-fei'),
                  children: <CellFormatSum value={data.vat} mode="inline" />,
                },
              ]}
            />

            <Divider orientation="left" orientationMargin={0}>
              <b>{t('Transaction.ding-dan-xin-xi')}</b>
            </Divider>
            <CusTable
              sticky
              rowKey="skuId"
              dataSource={data.products ?? undefined}
              columns={[
                { title: t('LocalProductList.chan-pin-id'), render: (_v, { skuId: v }) => v },
                { title: t('Delivery.chan-pin-sku'), render: (_v, { sku: v }) => v },
                { title: t('LocalProductList.ying-wen-pin-ming'), render: (_v, { productEnTitle: v }) => v },
                { title: t('2-common.shu-liang'), width: 100, render: (_v, { currentQuantity: v }) => v },
              ]}
            />
          </>
        )}
      </Drawer>
    )
  },
  {
    open: (props: IFreightDetailDrawerProps) => {
      updatePopup(<FreightDetailDrawer {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
