import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import dayjs from 'dayjs'
import { Button, Drawer } from 'antd'

import { CellFormatSum } from '@/components/CellFormatSum'
import { CusTable, TCusTableIns } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { DEFAULT_PAGE_SIZE, getDefaultPaginationProps } from '@/utils/pagination'
import { useDOCUMENT_TYPE_NAME_MAP, useEXPENSE_TYPE_NAME_MAP } from '../../utils'
import { apiGetFrozenBalanceDetails } from '../apis'
import { popupSlot } from '../utils'

const updatePopup = popupSlot.insert(null)

export const FrozenBalanceDetailsDrawer = Object.assign(
  ({ destroy }: { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)

    const [page, setPage] = useState<number>(1)
    const [size, setSize] = useState<number>(DEFAULT_PAGE_SIZE)

    const tableRef = useRef<TCusTableIns>(null)
    const {
      loading,
      data,
      run: getList,
    } = useRequest(apiGetFrozenBalanceDetails, {
      defaultParams: [page, size],
      onBefore: () => CusTable.scrollToTop(tableRef.current),
    })

    const documentTypeNameMap = useDOCUMENT_TYPE_NAME_MAP()
    const expenseTypeNameMap = useEXPENSE_TYPE_NAME_MAP()

    return (
      <Drawer
        styles={{ body: { paddingTop: 0, paddingBottom: 12 } }}
        title={t('Transaction.dong-jie-yueming-xi')}
        width={800}
        open={open}
        onClose={onHide}
        afterOpenChange={afterOpenChange}
        extra={<Button onClick={onHide}>{t('2-common.guan-bi')}</Button>}
      >
        <CusTable
          rowKey="id"
          loading={{ spinning: loading, delay: SPIN_DELAY }}
          dataSource={data?.records}
          columns={[
            {
              title: t('Transaction.jiao-yi-id'),
              render: (_v, { transactionId: v }) => v,
            },
            {
              title: t('Transaction.dan-ju-id'),
              render: (_v, { documentId: v }) => v,
            },
            {
              title: t('Transaction.dan-ju-lei-xing'),
              render: (_v, { documentType: v }) => documentTypeNameMap[v] || v,
            },
            {
              title: t('Transaction.fei-yong-lei-mu'),
              render: (_v, { expenseType: v }) => expenseTypeNameMap[v] || v,
            },
            {
              title: t('Transaction.dong-jie-jin-e'),
              width: 120,
              render: (_v, { amount: v }) => <CellFormatSum value={v} />,
            },
            {
              title: t('Transaction.dong-jie-shi-jian'),
              width: 150,
              render: (_v, { createdTime: v }) => dayjs(v).format($F_YMDHms),
            },
          ]}
          ref={tableRef}
          verticalFlexLayout
          pagination={{
            ...getDefaultPaginationProps(data?.total || 0),
            current: page,
            pageSize: size,
            onChange: (page, size) => {
              setPage(page)
              setSize(size)
              getList(page, size)
            },
          }}
        />
      </Drawer>
    )
  },
  {
    open: () => {
      updatePopup(<FrozenBalanceDetailsDrawer destroy={() => updatePopup(null)} />)
    },
  },
)
