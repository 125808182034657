import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { createPopupSlot } from '@/services/popupSlot'

export const popupSlot = createPopupSlot()

/** 交易分类值:交易分类名 */
export const useTYPE_NAME_MAP = (): Record<number, string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      1: t('Transaction.zhi-chu'),
      2: t('Transaction.shou-ru'),
      3: t('Transaction.dong-jie'),
      4: t('Transaction.jie-dong'),
    }),
    [t],
  )
}
