import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import { Button, Divider, Drawer, Result, Space } from 'antd'

import { CellFormatSum } from '@/components/CellFormatSum'
import { CusTable } from '@/components/CusTable'
import { Loading } from '@/components/Loading'
import { globalPopupSlot } from '../../globalPopupSlot'
import { useRule } from '../utils'
import { apiGetDetail } from './apis'
import logoImg from '@/assets/logo.webp'
import styles from './styles.module.less'

const updatePopup = globalPopupSlot.insert(null)
const WIDTH = 1000 // 会影响打印效果

/** 账单详情弹窗 */
export const DetailsDrawer = Object.assign(
  ({ invoiceId, merchantUserId, destroy }: { invoiceId: string; merchantUserId: string; destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = globalPopupSlot.useAntdPopupAnimation(destroy)
    return (
      <Drawer
        styles={{ body: { overflow: 'hidden' } }}
        title={t('9-nav.zhang-dan-xiang-qing')}
        width={WIDTH}
        open={open}
        onClose={onHide}
        afterOpenChange={afterOpenChange}
      >
        <iframe
          style={{ border: 0, height: '100%', width: '100%' }}
          src={`${import.meta.env.VITE_APP_BASE_NAME}/biz/transaction/bill/detail/${invoiceId}/${merchantUserId}`}
        />
      </Drawer>
    )
  },
  {
    open: (props: { invoiceId: string; merchantUserId: string }) => {
      updatePopup(<DetailsDrawer {...props} destroy={() => updatePopup(null)} />)
    },
  },
)

/** 账单详情（给路由的） */
export function Detail() {
  const { t } = useTranslation()
  const userRule = useRule()
  const { invoiceId, merchantUserId } = useParams<{ invoiceId: string; merchantUserId: string }>()
  const [printing, setPrinting] = useState(false)

  const { loading, data, error } = useRequest(() => {
    if (!invoiceId || !merchantUserId) throw new Error(t('1-error.can-shu-yi-chang'))
    return apiGetDetail(invoiceId, merchantUserId, true)
  })

  useEffect(() => {
    document.body.classList.add(styles.Detail_body)
    const styleEle = document.createElement('style')
    styleEle.innerHTML = `@media print { @page { size: portrait; } }` // 设置打印方向为纵向
    document.body.appendChild(styleEle)
    return () => {
      document.body.classList.remove(styles.Detail_body)
      document.body.removeChild(styleEle)
    }
  }, [])

  const getContent = () => {
    if (loading) return <Loading />
    if (error) return <Result status="error" title={t('Transaction.bao-qian-zhang-dan-huo-qu-chu-cuo')} />

    return (
      <Space style={{ display: 'flex' }} size={24} direction="vertical">
        <Space size={30} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <img src={logoImg} height={40} />
          <div>
            <b>{t('Setting.gong-si-di-zhi')}:&nbsp;</b>
            公司地址公司地址公司地址公司地址公司地址公司地址公司地址公司地址公司地址公司地址公司地址公司地址公司地址
            <br />
            <b>{t('Transaction.lian-xi-dian-hua')}:&nbsp;</b>+86 400-800-90
          </div>
          {!!data && !printing && (
            <Button
              type="primary"
              onClick={() => {
                setPrinting(true)
                setTimeout(() => {
                  window.print()
                  setTimeout(() => {
                    setPrinting(false)
                  }, 100)
                }, 100)
              }}
            >
              {t('Transaction.pdf-xia-zai-da-yin')}
            </Button>
          )}
        </Space>

        <Divider style={{ margin: 0 }} />

        <Space size={30} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <b>{t('Transaction.zhang-dan-di-zhi')}:&nbsp;</b>
            {data?.invoiceAddress || '--'}
          </div>
          <div style={{ whiteSpace: 'nowrap' }}>
            <div>
              <b>{t('Transaction.ji-fei-zhou-qi')}:&nbsp;</b>
              {data?.$billingPeriod}
            </div>
            <div>
              <b>{t('Transaction.zhang-dan-id')}:&nbsp;</b>
              {data?.invoiceId}
            </div>
            <div>
              <b>
                {userRule !== 'customer' && t('Transaction.shang-jia')}
                {t('2-common.you-xiang')}:
              </b>
              &nbsp;{data?.email}
            </div>
          </div>
        </Space>

        <CusTable
          bordered
          size="small"
          title={() => (
            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{t('Transaction.zhang-dan-ming-xi')}</div>
          )}
          columns={[
            { title: '#', align: 'center', dataIndex: '$serialNumber' },
            { title: t('Transaction.fei-yong-lei-mu'), align: 'center', dataIndex: '$expenseCategory' },
            { title: t('Transaction.ji-fei-zhou-qi'), align: 'center', dataIndex: '$billingPeriod' },
            {
              title: t('Transaction.fei-yong'),
              align: 'center',
              dataIndex: '$cost',
              render: v => <CellFormatSum value={v} />,
            },
          ]}
          dataSource={Array(8)
            .fill(null)
            .map((_d, i) => ({
              key: i,
              $serialNumber: i + 1, // 序号
              $expenseCategory: {
                0: t('Transaction.cao-zuo-fei'),
                1: t('Transaction.yun-fei'),
                2: t('Transaction.cang-chu-fei'),
                3: t('Transaction.ti-huo-fei'),
                4: t('Transaction.jia-gong-fei'),
                5: t('Transaction.gua-hao-fei'),
                6: t('Transaction.vat-zeng-zhi-shui'),
                7: t('Transaction.vat-shou-xu-fei'),
              }[i],
              $billingPeriod: data?.$billingPeriod,
              $cost: {
                0: data?.handlingFee,
                1: data?.freight,
                2: data?.storageFee,
                3: data?.deliveryTakingFee,
                4: data?.processingFee,
                5: data?.registrationFee,
                6: data?.vatFee,
                7: data?.vatHandlingFee,
              }[i],
            }))}
          summary={() => (
            <CusTable.Summary.Row>
              <CusTable.Summary.Cell index={0} colSpan={3} align="center">
                <b>{t('Transaction.zong-fei-yong')}</b>
              </CusTable.Summary.Cell>
              <CusTable.Summary.Cell index={0} colSpan={3}>
                <CellFormatSum value={data?.sumFee} />
              </CusTable.Summary.Cell>
            </CusTable.Summary.Row>
          )}
        />

        <div>&nbsp;</div>

        <CusTable
          className={styles.payInfoTable}
          bordered
          size="small"
          title={() => <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{t('Transaction.zhi-fu-xin-xi')}</div>}
          columns={[
            {
              title: t('Transaction.dian-hui-shuo-ming'),
              align: 'center',
              width: '60%',
              render: () => (
                <>
                  领赫公司电汇收款信息说明
                  <br />
                  Bank Name: HSBC BANK PLC, TEL AVIV
                  <br />
                  Beneficiary Name: AppsFlyer LTD
                  <br />
                  Bank Code: 23
                  <br />
                  Branch Code: 101
                  <br />
                  Bank Address: Amot Atrium Tower 2 Jabotinsky Street,
                  <br />
                  30th Floor Ramat Gan, Israel 5250501
                  <br />
                  Credit card payment- Click here
                  <br />
                  Flywire payment- Click here ($25 min) To pay this invoice with
                  <br />
                  local bank transfer or local credit/debit card (no international
                  <br />
                  fees)
                  <br />
                  PayPal payment- Please send to the following email address:
                  <br />
                  accounting@appsflyer.com
                  <br />
                  For payments in USD:
                  <br />
                  Account Number: 029379-500
                  <br />
                  IBAN: IL230231010101029379500
                  <br />
                  Swift Code: HSBCILIT
                  <br />
                  *For Customers who are using intermediary bank please use
                  <br />
                  correspondent bank HSBC NY Swift: MRMDUS33
                  <br />
                  *Invoice number must be referenced to all payments
                  <br />
                  For payments in EUR:
                  <br />
                  Account Number: 029379-501
                  <br />
                  IBAN: IL930231010101029379501
                  <br />
                  For payments in GBP:
                  <br />
                  Account Number: 029379-502
                  <br />
                  IBAN: IL660231010101029379502
                  <br />
                  *Please include the invoice number (INVISR110110) and
                  <br />
                  AppsFlyer IBAN number in your wire instructions
                </>
              ),
            },
            {
              title: t('Transaction.qi-ta-zhi-fu-fang-shi'),
              align: 'center',
              render: () => (
                <>
                  领赫公司其他收款方式
                  <br />
                  Credit card payment- Click here
                  <br />
                  Flywire payment- Click here ($25 min) To pay this invoice with
                  <br />
                  local bank transfer or local credit/debit card (no international
                  <br />
                  fees)
                  <br />
                  PayPal payment- Please send to the following email address:
                  <br />
                  accounting@appsflyer.com
                  <br />
                  For payments in USD:
                  <br />
                  Account Number: 029379-500
                  <br />
                  IBAN: IL230231010101029379500
                  <br />
                  Swift Code: HSBCILIT
                  <br />
                  *For Customers who are using intermediary bank please use
                  <br />
                  correspondent bank HSBC NY Swift: MRMDUS33
                  <br />
                  *Invoice number must be referenced to all payments
                </>
              ),
            },
          ]}
          dataSource={[{ key: '0' }]}
        />
      </Space>
    )
  }

  return createPortal(
    <div className={styles.Detail} style={{ width: WIDTH - 100 }}>
      {getContent()}
    </div>,
    document.body,
  )
}
