import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import { Button, Card, Space } from 'antd'

import { toThousands } from '@/utils/utils'
import { apiGetBalance } from '../apis'
import { FrozenBalanceDetailsDrawer } from './FrozenBalanceDetailsDrawer'
import { ReceivedBankModal } from './ReceivedBankModal'

/** 余额信息（仅商家侧） */
export const Balance = () => {
  const { t } = useTranslation()
  const { data, mutate } = useRequest(apiGetBalance, {
    cacheKey: 'Transaction/Recharge/Balance',
    cacheTime: -1,
    onError: () => void mutate(undefined),
  })

  return (
    <Space size={20} style={{ display: 'flex' }}>
      <Card
        size="small"
        bordered={false}
        style={{ width: 300 }}
        title={t('Transaction.ke-yong-yu-e')}
        extra={
          <Button type="link" onClick={() => ReceivedBankModal.open()}>
            {t('Transaction.qu-chong-zhi')}
          </Button>
        }
      >
        <div style={{ textAlign: 'right', fontSize: '1.2em' }}>{toThousands(data?.availableBalance)}</div>
      </Card>
      <Card size="small" bordered={false} style={{ width: 300 }} title={t('Transaction.zong-yu-e')}>
        <div style={{ textAlign: 'right', fontSize: '1.2em' }}>{toThousands(data?.totalBalance)}</div>
      </Card>
      <Card
        size="small"
        bordered={false}
        style={{ width: 300 }}
        title={t('Transaction.dong-jie-yu-e')}
        extra={
          <Button type="link" onClick={() => FrozenBalanceDetailsDrawer.open()}>
            {t('Transaction.cha-kan-ming-xi')}
          </Button>
        }
      >
        <div style={{ textAlign: 'right', fontSize: '1.2em' }}>{toThousands(data?.frozenBalance)}</div>
      </Card>
    </Space>
  )
}
