import { useEffect } from 'react'
import { useRequest } from 'ahooks'
import { Col, Empty, Row } from 'antd'

import { Loading } from '@/components/Loading'
import { apiGetPayBankRecords } from '../apis'
import { IBank } from '../interface'
import { BankCard } from './BankCard'

/** 商家银行卡选择器（卡片形式） */
export const MerchantBankCardSelect = ({
  merchantUserId,
  value,
  onChange,
  onFetch,
}: {
  /** 商家用户id */
  merchantUserId: string | null | undefined
  /** 付款银行账号 */
  value?: string
  /** 用户选中时触发 */
  onChange?: (value: string, bank: Omit<IBank, 'id'>) => void
  /** 数据拉取过程回调 */
  onFetch?: (info: { fetching: boolean; banks?: Omit<IBank, 'id'>[]; error?: Error }) => void
}) => {
  merchantUserId = `${merchantUserId || ''}`

  const { loading, data, mutate, run } = useRequest(apiGetPayBankRecords, {
    manual: true,
    onBefore: () => {
      mutate(undefined)
      onFetch?.({ fetching: true })
    },
    onSuccess: banks => {
      onFetch?.({ fetching: false, banks })
    },
    onError: error => {
      mutate(undefined)
      onFetch?.({ fetching: false, error })
    },
  })

  useEffect(() => {
    if (!merchantUserId) {
      mutate(undefined)
      return
    }
    run(merchantUserId)
  }, [merchantUserId, mutate, run])

  return (
    <div style={{ minHeight: 80 }}>
      {loading ? (
        <Loading />
      ) : !data?.length ? (
        <Empty imageStyle={{ height: 50 }} />
      ) : (
        <Row wrap gutter={[12, 12]}>
          {data.map(d => (
            <Col span={12} key={d.cardNumber}>
              <BankCard
                style={{ width: 'auto' }}
                bank={d}
                checked={value === d.cardNumber}
                onClick={() => onChange?.(d.cardNumber, d)}
              />
            </Col>
          ))}
        </Row>
      )}
    </div>
  )
}
