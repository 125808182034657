import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { createPopupSlot } from '@/services/popupSlot'

export const popupSlot = createPopupSlot()

/** 充值状态值:充值状态名 */
export const useRECHARGE_STATUS_NAME_MAP = (): Record<number, string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      0: t('Transaction.shen-he-zhong'),
      1: t('Transaction.yi-dao-zhang'),
      2: t('Transaction.yi-ju-jue'),
    }),
    [t],
  )
}
