import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { useRequest, useUpdateEffect } from 'ahooks'
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'
import { Col, Descriptions, Divider, Form, FormProps, InputNumber, Row, Select, Space, Spin } from 'antd'

import { FormItemDatePicker } from '@/components/FormItemDateTime'
import { FormItemValidatingInput } from '@/components/FormItemValidatingInput'
import { useFormAllValues } from '@/hooks/hooks'
import { useCURRENCY_DES_MAP, useCURRENCY_OPTS } from '@/pages/Biz/consts/consts'
import { formAutoTrimOnBlur, toThousands } from '@/utils/utils'
import { apiBankStatementVerification, apiGetExchangeRate } from '../apis'
import { TApprovalFormValues } from '../interface'
import { Ctx } from './Ctx'
import { createInitialApprovalFormValues } from './utils'

/** 收款信息 */
export const ReceivedInfo = () => {
  const { t } = useTranslation()
  const mode = useContextSelector(Ctx, v => v.mode)
  const setApprovalForm = useContextSelector(Ctx, v => v.setApprovalForm)
  const setBtnDisabled = useContextSelector(Ctx, v => v.setBtnDisabled)
  const detail = useContextSelector(Ctx, v => v.detail)

  const [form] = Form.useForm<TApprovalFormValues>()
  const initialFormValues = useMemo(() => createInitialApprovalFormValues(), [])
  const allValues = useFormAllValues(form, initialFormValues)

  const readOnly = mode === 'view'
  const currencyDesMap = useCURRENCY_DES_MAP()
  const currencyOpts = useCURRENCY_OPTS()

  const formProps: FormProps<TApprovalFormValues> = {
    form,
    scrollToFirstError: { block: 'center', behavior: 'smooth' },
    layout: 'vertical',
    preserve: true,
    initialValues: initialFormValues,
    onBlur: e => formAutoTrimOnBlur({ event: e, form, includeField: ['transferBankStatement'] }),
  }

  // 处理实际到账币种汇率、实际充值金额
  const { loading: exchangeRateFetching, run: getExchangeRateMap } = useRequest(
    async (date: string) => apiGetExchangeRate(date),
    {
      manual: true,
      onBefore: () => form.setFieldValue('receivedExchangeRate', undefined),
      onSuccess: data => {
        form.setFieldValue('receivedExchangeRate', data?.[detail?.transferCurrencyType || ''])
      },
    },
  )
  useUpdateEffect(() => setBtnDisabled(exchangeRateFetching), [exchangeRateFetching])
  useEffect(() => {
    if (!allValues.receivedDate) return
    getExchangeRateMap(allValues.receivedDate)
  }, [allValues.receivedDate, getExchangeRateMap])
  useEffect(() => {
    let val
    if (allValues.receivedAmount && allValues.receivedExchangeRate) {
      val = new BigNumber(allValues.receivedExchangeRate).times(allValues.receivedAmount).toFixed(2, 1)
      val = val === 'NaN' ? undefined : val
    }
    form.setFieldValue('actualRechargeAmount', val)
  }, [allValues.receivedAmount, allValues.receivedExchangeRate, form])

  // 传送表单实例
  useEffect(() => setApprovalForm(form), [form, setApprovalForm])

  // 大标题
  const getLabel = () => (
    <Divider orientation="left" orientationMargin={0}>
      <b>{t('Transaction.shou-kuan-xin-xi')}</b>
    </Divider>
  )

  return readOnly ? (
    // 查阅模式
    <>
      {getLabel()}
      <Descriptions
        size="small"
        bordered
        column={6}
        labelStyle={{ whiteSpace: 'nowrap' }}
        items={[
          {
            span: 6,
            label: t('Transaction.shi-ji-dao-zhang-jin-e'),
            children: (
              <Space size={12}>
                <>{toThousands(detail?.receivedAmount)}</>
                <>{currencyDesMap[detail?.transferCurrencyType + ''] || '--'}</>
              </Space>
            ),
          },
          {
            span: 6,
            label: t('Transaction.shou-xu-fei'),
            children: (
              <Space size={12}>
                <>{toThousands(detail?.fee)}</>
                <>{currencyDesMap[detail?.feeCurrencyType + ''] || '--'}</>
              </Space>
            ),
          },
          {
            span: 6,
            label: t('Transaction.shi-ji-dao-zhang-ri-qi'),
            children: detail?.receivedDate ? dayjs(detail.receivedDate).format($F_YMD) : '--',
          },
          {
            span: 6,
            label: t('Transaction.dao-zhang-liu-shui-hao'),
            children: detail?.receivedBankStatement || '--',
          },
          {
            span: 6,
            label: t('Transaction.shi-ji-dao-zhang-bi-zhong-hui-shuai'),
            children: detail?.receivedExchangeRate ? `USD ${detail.receivedExchangeRate}` : '--',
          },
          {
            span: 6,
            label: t('Transaction.shi-ji-chong-zhi-jin-e'),
            children: (
              <Space size={12}>
                <>{toThousands(detail?.actualRechargeAmount)}</>
                <>{currencyDesMap['USD']}</>
              </Space>
            ),
          },
        ]}
      />
    </>
  ) : (
    // 编辑模式
    <>
      {getLabel()}
      <Form {...formProps}>
        <Row wrap gutter={12}>
          <Col span={15}>
            <Form.Item
              label={t('Transaction.shi-ji-dao-zhang-jin-e')}
              name="receivedAmount"
              rules={[{ required: true }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0.01}
                max={Number(detail?.transferAmount) || 0}
                precision={2}
                stringMode
                formatter={v => toThousands(v, { placeholder: '', precision: null })}
                placeholder={t('2-common.qing-shu-ru')}
              />
            </Form.Item>
          </Col>

          <Col span={9}>
            <Form.Item label={<></>}>{currencyDesMap[detail?.transferCurrencyType + ''] || '--'}</Form.Item>
          </Col>

          <Col span={15}>
            <Form.Item label={t('Transaction.shou-xu-fei')} name="fee" rules={[{ required: true }]}>
              <InputNumber
                style={{ width: '100%' }}
                min={0.01}
                precision={2}
                stringMode
                formatter={v => toThousands(v, { placeholder: '', precision: null })}
                placeholder={t('2-common.qing-shu-ru')}
              />
            </Form.Item>
          </Col>

          <Col span={9}>
            <Form.Item
              label={t('Transaction.shou-xu-fei-bi-zhong')}
              name="feeCurrencyType"
              rules={[{ required: true }]}
            >
              <Select popupMatchSelectWidth={false} options={currencyOpts} placeholder={t('2-common.qing-xuan-ze')} />
            </Form.Item>
          </Col>

          <Col span={15}>
            <Form.Item label={t('Transaction.shi-ji-dao-zhang-ri-qi')} name="receivedDate" rules={[{ required: true }]}>
              <FormItemDatePicker
                style={{ width: '100%' }}
                disabledDate={curr => curr.endOf('day') > dayjs().endOf('day')}
              />
            </Form.Item>
          </Col>

          <Col span={15}>
            <Form.Item
              label={t('Transaction.dao-zhang-liu-shui-hao')}
              name="receivedBankStatement"
              validateTrigger="onBlur"
              rules={[
                { required: true },
                {
                  async validator(_r, val) {
                    const v = `${val || ''}`.trim()
                    if (!v) return
                    const res = await apiBankStatementVerification({ bankStatement: v, type: 2 })
                    if (res !== true) throw new Error(t('Transaction.gai-liu-shui-hao-yi-chu-li-guo'))
                  },
                },
              ]}
            >
              <FormItemValidatingInput />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label={t('Transaction.shi-ji-dao-zhang-bi-zhong-hui-shuai')}>
              <span style={{ marginLeft: 3 }}>
                {exchangeRateFetching ? (
                  <Spin />
                ) : allValues.receivedExchangeRate ? (
                  <Space size={12}>
                    USD
                    <>{allValues.receivedExchangeRate}</>
                  </Space>
                ) : (
                  '--'
                )}
              </span>
            </Form.Item>
          </Col>

          <Col span={15 - 7}>
            <Form.Item label={t('Transaction.shi-ji-chong-zhi-jin-e')}>
              <span style={{ marginLeft: 3 }}>
                {exchangeRateFetching && allValues.receivedAmount ? (
                  <Spin />
                ) : (
                  toThousands(allValues.actualRechargeAmount)
                )}
              </span>
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item label={<></>} style={{ textAlign: 'right' }}>
              <span style={{ marginLeft: 3 }}>{currencyDesMap['USD']}</span>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}
