import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { Button, Input, message, Modal, Space } from 'antd'

import { apiAdd, apiApprovalRejection, apiApproved } from '../apis'
import { TApprovalFormValues, TFormValues } from '../interface'
import { Ctx } from './Ctx'

/** 操作 */
export const Actions = ({ onSuccess }: { onSuccess: () => void }) => {
  const { t } = useTranslation()
  const mode = useContextSelector(Ctx, v => v.mode)
  const mainForm = useContextSelector(Ctx, v => v.mainForm)
  const approvalForm = useContextSelector(Ctx, v => v.approvalForm)
  const btnDisabled = useContextSelector(Ctx, v => v.btnDisabled)
  const id = useContextSelector(Ctx, v => v.detail?.id)
  const [validating, setValidating] = useState(false)

  return (
    <Space>
      {(mode === 'submit' || mode === 'resubmit') && (
        <Button
          type="primary"
          loading={validating}
          disabled={btnDisabled}
          onClick={async () => {
            if (!mainForm) return
            setValidating(true)
            const values: TFormValues = mainForm.getFieldsValue(true) // 先取值缓存，避免校验过程中有改动
            await mainForm.validateFields().finally(() => setValidating(false))
            Modal.confirm({
              title: t('Transaction.que-ren-yao-ti-jiao-ma'),
              async onOk() {
                await apiAdd(values)
                message.success(t('2-common.cao-zuo-cheng-gong'))
                onSuccess()
              },
            })
          }}
        >
          {validating ? t('Transaction.xiao-yan-zhong') : t('2-common.ti-jiao')}
        </Button>
      )}
      {mode === 'approval' && (
        <>
          <Button
            type="primary"
            loading={validating}
            disabled={btnDisabled}
            onClick={async () => {
              if (!approvalForm || !id) return
              setValidating(true)
              const values: TApprovalFormValues = approvalForm.getFieldsValue(true) // 先取值缓存，避免校验过程中有改动
              await approvalForm.validateFields().finally(() => setValidating(false))
              if (!values.receivedExchangeRate) {
                message.error(t('Transaction.cha-xun-shi-ji-dao-zhang-bi-zhong-hui-shuai-shi-bai'))
                return
              }
              if (!values.actualRechargeAmount) {
                message.error(t('Transaction.shi-ji-chong-zhi-jineji-suan-shi-bai'))
                return
              }
              Modal.confirm({
                title: t('Transaction.que-ren-yao-tong-guo-ma'),
                async onOk() {
                  await apiApproved(id, values)
                  message.success('操作成功')
                  onSuccess()
                },
              })
            }}
          >
            {validating ? t('Transaction.xiao-yan-zhong') : t('2-common.tong-guo')}
          </Button>
          <Button
            type="primary"
            danger
            onClick={async () => {
              if (!id) return
              let reason = ''
              Modal.confirm({
                title: t('Transaction.que-ren-yao-ju-jue-ma'),
                content: (
                  <Input.TextArea
                    placeholder={t('Transaction.qing-shu-ru-ju-jue-yuan-yin')}
                    rows={3}
                    onChange={e => {
                      reason = e.target.value.trim()
                    }}
                  />
                ),
                async onOk() {
                  if (!reason) {
                    message.error(t('Transaction.qing-shu-ru-ju-jue-yuan-yin'))
                    throw new Error()
                  }
                  await apiApprovalRejection(id, reason)
                  message.success(t('2-common.cao-zuo-cheng-gong'))
                  onSuccess()
                },
              })
            }}
          >
            {t('2-common.ju-jue')}
          </Button>
        </>
      )}
    </Space>
  )
}
