import axios, { AxiosRequestConfig } from 'axios'

import {
  IBalance,
  IBank,
  IDetail,
  IFrozenBalanceDetailsRes,
  IReceivBank,
  IReqData,
  IRes,
  TApprovalFormValues,
  TFormValues,
} from './interface'
import './__mock__'

/** 获取余额信息（仅商家侧） */
export const apiGetBalance = async (): Promise<IBalance> => {
  return axios.get(`/linkhub_oms/user/account`).then(res => res.$data || {})
}

/** 获取冻结余额明细（仅商家侧） */
export const apiGetFrozenBalanceDetails = async (page: number, size: number): Promise<IFrozenBalanceDetailsRes> => {
  return axios.get(`/linkhub_oms/user/account/flow/freeze`, { params: { page, size } }).then(res => res.$data)
}

/** 获取主列表 */
export const apiGetRecords = async (params: IReqData, signal?: AxiosRequestConfig['signal']): Promise<IRes> => {
  return axios.get(`/linkhub_oms/user/account/recharge`, { params, signal }).then(res => res.$data)
}

/** 获取付款银行卡 */
export const apiGetPayBankRecords = async (merchantUserId: string): Promise<IBank[]> => {
  return axios
    .get(`/linkhub_oms/user/account/recharge/bank/card/pay`, { params: { merchantUserId } })
    .then(res => res.$data || [])
}

/** 获取收款银行卡 */
export const apiGetReceivBankRecords = async (): Promise<IReceivBank[]> => {
  return axios.get(`/linkhub_oms/user/account/recharge/bank/card/receive`).then(res => res.$data || [])
}

/** 获取各币种兑换美元汇率，不传 date 则默认当日 */
export const apiGetExchangeRate = async (
  date?: string, // 默认当日
): Promise<Record<string, string | undefined>> => {
  return axios
    .get(`/linkhub_oms/user/account/recharge/exchange/rate`, { params: { date } })
    .then(res => res.$data || {})
}

/** 流水号校验 */
export const apiBankStatementVerification = async (params: {
  /** 流水号 */
  bankStatement: string
  /** 1-转账流水号;2-到账流水号 */
  type: 1 | 2
}): Promise<boolean> => {
  return axios.get(`/linkhub_oms/user/account/recharge/bank/statement/check`, { params }).then(res => res.$data)
}

/** 获取充值详情 */
export const apiGetDetail = async (transactionId: string): Promise<IDetail> => {
  return axios.get(`/linkhub_oms/user/account/recharge/${transactionId}`).then(res => res.$data || {})
}

/** 充值提交 */
export const apiAdd = async (reqData: TFormValues) => {
  await axios.post(`/linkhub_oms/user/account/recharge`, reqData)
}

/** 充值提交-审批通过（仅财务侧） */
export const apiApproved = async (id: number, reqData: TApprovalFormValues) => {
  await axios.put(`/linkhub_oms/user/account/recharge/suc/${id}`, reqData)
}

/** 充值提交-审批拒绝（仅财务侧） */
export const apiApprovalRejection = async (id: number, reason: string) => {
  await axios.put(`/linkhub_oms/user/account/recharge/fail/${id}`, { reason })
}
