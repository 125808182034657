import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { createPopupSlot } from '@/services/popupSlot'

export const popupSlot = createPopupSlot()

/** 匹配状态值:匹配状态名 */
export const useMATCH_STATUS_NAME_MAP = (): Record<number, string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      0: t('Transaction.wei-pi-pei'),
      1: t('Transaction.yi-pi-pei'),
    }),
    [t],
  )
}
