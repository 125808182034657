import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import dayjs from 'dayjs'
import { Button, Space } from 'antd'
import type { ColumnsType } from 'antd/es/table'

import { CellFormatSum } from '@/components/CellFormatSum'
import { CusTable, TCusTableIns } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { useControllerRef } from '@/hooks/useAbortController'
import { DEFAULT_PAGE_SIZE, getDefaultPaginationProps } from '@/utils/pagination'
import { MerchantSelect } from '../../components/MerchantSelect'
import { useRule } from '../utils'
import { apiGetRecords } from './apis'
import { DetailsDrawer } from './Detail'
import { IRecord, IReqData, IRes } from './interface'
import styles from './styles.module.less'

export const Bill = () => {
  const { t } = useTranslation()
  const userRule = useRule()

  const [merchantUserId, setMerchantUserId] = useState<IReqData['merchantUserId']>()
  const [page, setPage] = useState<IReqData['page']>(1)
  const [size, setSize] = useState<IReqData['size']>(DEFAULT_PAGE_SIZE)

  const reqData: IReqData = {
    merchantUserId,
    page,
    size,
  }
  const tableRef = useRef<TCusTableIns>(null)
  const controllerRef = useControllerRef()
  const {
    loading,
    data,
    run: getList,
  } = useRequest<IRes, [IReqData]>(
    async req => {
      controllerRef.current.abort()
      controllerRef.current = new AbortController()
      return apiGetRecords(req, controllerRef.current.signal)
    },
    {
      defaultParams: [reqData],
      onBefore: () => CusTable.scrollToTop(tableRef.current),
    },
  )

  const columns: ColumnsType<IRecord> = [
    {
      title: t('Transaction.zhang-dan-id'),
      render: (_v, { invoiceId: v }) => v,
    },
    {
      title: t('2-common.shang-jia-ming-cheng'),
      hidden: userRule === 'customer',
      render: (_v, { merchantUserName: v }) => v,
    },
    {
      title: t('Transaction.fei-yong'),
      width: 120,
      align: 'right',
      render: (_v, { fee: v }) => <CellFormatSum value={v} />,
    },
    {
      title: t('Transaction.ji-fei-zhou-qi'),
      render: (_v, { billingCycle: v }) => v,
    },
    {
      title: t('Transaction.kai-piao-ri-qi'),
      render: (_v, { invoiceDate: v }) => v && dayjs(v).format($F_YMD),
    },
    {
      title: t('2-common.cao-zuo'),
      render: (_v, { invoiceId, merchantUserId }) => {
        return (
          <Button type="link" onClick={() => DetailsDrawer.open({ invoiceId, merchantUserId })}>
            {t('2-common.cha-kan')}
          </Button>
        )
      },
    },
  ]

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        {userRule === 'employee' && (
          <div className={styles.header_filter}>
            <Space size={16}>
              <MerchantSelect
                style={{ width: 160 }}
                value={merchantUserId}
                onChange={merchantUserId => {
                  setMerchantUserId(merchantUserId)
                  setPage(1)
                  getList({ ...reqData, merchantUserId, page: 1 })
                }}
              />
            </Space>
          </div>
        )}
        <div className={styles.header_action}></div>
      </div>

      <div className={styles.table}>
        <CusTable
          rowKey="invoiceId"
          loading={{ spinning: loading, delay: SPIN_DELAY }}
          dataSource={data?.records}
          columns={columns}
          ref={tableRef}
          verticalFlexLayout
          pagination={{
            ...getDefaultPaginationProps(data?.total || 0),
            current: page,
            pageSize: size,
            onChange: (page, size) => {
              setPage(page)
              setSize(size)
              getList({ ...reqData, page, size })
            },
          }}
        />
      </div>
    </div>
  )
}
