import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import dayjs from 'dayjs'
import { Button, Descriptions, Divider, Drawer, Space } from 'antd'

import { CellFormatSum } from '@/components/CellFormatSum'
import { CusTable } from '@/components/CusTable'
import { Loading } from '@/components/Loading'
import { apiGetDeliveryDetail } from './apis'
import { popupSlot } from './utils'

const updatePopup = popupSlot.insert(null)

interface IDeliveryDetailDrawerProps {
  transactionId: string
}

/** 详情：1-提货费; */
export const DeliveryDetailDrawer = Object.assign(
  ({ transactionId, destroy }: IDeliveryDetailDrawerProps & { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)

    const { data, loading } = useRequest(apiGetDeliveryDetail, { defaultParams: [transactionId] })

    return (
      <Drawer
        styles={{ body: { paddingTop: 0 } }}
        title={t('Transaction.ti-huo-fei-xiang-qing')}
        width={666}
        open={open}
        onClose={onHide}
        afterOpenChange={afterOpenChange}
        extra={
          <Space>
            <Button onClick={onHide}>{t('2-common.guan-bi')}</Button>
          </Space>
        }
      >
        {loading ? (
          <Loading />
        ) : !data ? null : (
          <>
            <Descriptions
              style={{ marginTop: 24 }}
              size="small"
              bordered
              column={1}
              labelStyle={{ whiteSpace: 'nowrap' }}
              items={[
                {
                  label: t('Delivery.ti-huo-id'),
                  children: data.sysEntryOrderCode,
                },
                {
                  label: t('Delivery.shen-qing-ri-qi'),
                  children: dayjs(data.pickUpApplyTime).format($F_YMD),
                },
                {
                  label: t('Delivery.ti-huo-fei-yong'),
                  children: <CellFormatSum value={data.pickUpFee} mode="inline" />,
                },
              ]}
            />

            <Divider orientation="left" orientationMargin={0}>
              <b>{t('Delivery.chan-pin-xin-xi')}</b>
            </Divider>
            <CusTable
              sticky
              rowKey="skuId"
              dataSource={data.products ?? undefined}
              columns={[
                { title: t('LocalProductList.chan-pin-id'), render: (_v, { skuId: v }) => v },
                { title: t('Delivery.chan-pin-sku'), render: (_v, { sku: v }) => v },
                { title: t('LocalProductList.ying-wen-pin-ming'), render: (_v, { productEnTitle: v }) => v },
                { title: t('2-common.shu-liang'), width: 100, render: (_v, { currentQuantity: v }) => v },
              ]}
            />
          </>
        )}
      </Drawer>
    )
  },
  {
    open: (props: IDeliveryDetailDrawerProps) => {
      updatePopup(<DeliveryDetailDrawer {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
