import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import { Button, Descriptions, Divider, Drawer, Space } from 'antd'

import { CellFormatSum } from '@/components/CellFormatSum'
import { Loading } from '@/components/Loading'
import { apiGetProcessingDetail } from './apis'
import { popupSlot } from './utils'

const updatePopup = popupSlot.insert(null)

interface IProcessingDetailDrawerProps {
  transactionId: string
}

/** 详情：6-加工费; */
export const ProcessingDetailDrawer = Object.assign(
  ({ transactionId, destroy }: IProcessingDetailDrawerProps & { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)

    const { data, loading } = useRequest(apiGetProcessingDetail, { defaultParams: [transactionId] })

    return (
      <Drawer
        title={t('Transaction.jia-gong-fei-xiang-qing')}
        width={666}
        open={open}
        onClose={onHide}
        afterOpenChange={afterOpenChange}
        extra={
          <Space>
            <Button onClick={onHide}>{t('2-common.guan-bi')}</Button>
          </Space>
        }
      >
        {loading ? (
          <Loading />
        ) : !data ? null : (
          <>
            <Descriptions
              size="small"
              bordered
              column={1}
              labelStyle={{ whiteSpace: 'nowrap' }}
              items={[
                {
                  label: t('Transaction.jia-gong-dan-id'),
                  children: data.processOrderCode,
                },
                {
                  label: t('LocalProductList.jia-gong-fei'),
                  children: <CellFormatSum value={data.processingFee} mode="inline" />,
                },
              ]}
            />

            <Divider orientation="left" orientationMargin={0}>
              <b>{t('Transaction.zu-he-chan-pin')}</b>
            </Divider>

            <Descriptions
              size="small"
              bordered
              column={1}
              labelStyle={{ whiteSpace: 'nowrap' }}
              items={[
                {
                  label: t('LocalProductList.chan-pin-id'),
                  children: data.combinedProduct?.skuId ?? '--',
                },
                {
                  label: t('Delivery.chan-pin-sku'),
                  children: data.combinedProduct?.sku ?? '--',
                },
                {
                  label: t('LocalProductList.ying-wen-pin-ming'),
                  children: data.combinedProduct?.productEnTitle ?? '--',
                },
                {
                  label: t('2-common.shu-liang'),
                  children: data.combinedProduct?.currentQuantity ?? '--',
                },
              ]}
            />
          </>
        )}
      </Drawer>
    )
  },
  {
    open: (props: IProcessingDetailDrawerProps) => {
      updatePopup(<ProcessingDetailDrawer {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
