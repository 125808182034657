import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import { Select } from 'antd'

import { Loading } from '@/components/Loading'
import { apiGetReceivBankRecords } from '../apis'
import { IBank } from '../interface'

/** 收款银行卡选择器 */
export const ReceivedBankSelect = ({
  value,
  onChange,
}: {
  /** 收款银行账号 */
  value?: string
  /** 用户选中时触发 */
  onChange?: (value: string, bank: Omit<IBank, 'id'>) => void
}) => {
  const { t } = useTranslation()
  const { loading, data, mutate } = useRequest(apiGetReceivBankRecords, {
    cacheKey: 'Transaction/Recharge/components/ReceivedBankSelect',
    cacheTime: -1,
    onError: () => void mutate(undefined),
  })

  return (
    <Select
      placeholder={t('2-common.qing-xuan-ze')}
      popupMatchSelectWidth={false}
      notFoundContent={loading ? <Loading delay={0} /> : undefined}
      options={data?.map(d => ({
        value: d.cardNumber,
        label: `${d.bankName} - ${d.cardNumber}`,
      }))}
      value={value || undefined}
      onChange={val => {
        onChange?.(val, data!.find(d => d.cardNumber === val)!)
      }}
    />
  )
}
