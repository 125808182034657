import { useTranslation } from 'react-i18next'
import { Form, GetProps, Input } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

/** 异步校验输入框 */
export const FormItemValidatingInput = (props: GetProps<typeof Input>) => {
  const { t } = useTranslation()
  const { status } = Form.Item.useStatus()

  return (
    <Input
      allowClear
      placeholder={t('2-common.qing-shu-ru')}
      {...props}
      suffix={status === 'validating' ? <LoadingOutlined /> : props.suffix}
    />
  )
}
