import { IDetail, TApprovalFormValues, TFormValues } from '../interface'

/** 根据详情创建初始的主表单数据 */
export const createInitialFormValues = (
  merchantUserId: string | null | undefined,
  data: Partial<IDetail> | null | undefined,
): TFormValues => {
  const values: TFormValues = {
    merchantUserId: data?.merchantUserId || merchantUserId || undefined,
    payBankName: data?.payBankName || undefined,
    payAccount: data?.payAccount || undefined,
    payCardNumber: data?.payCardNumber || undefined,
    transferCurrencyType: data?.transferCurrencyType || undefined,
    transferAmount: data?.transferAmount || undefined,
    receivedBankName: data?.receivedBankName || undefined,
    receivedCardNumber: data?.receivedCardNumber || undefined,
    transferBankStatement: data?.transferBankStatement || undefined,
    transferDate: data?.transferDate || undefined,
    transferExchangeRate: data?.transferExchangeRate || undefined,
    attachmentList: data?.attachmentList || [],
  }
  return values
}

/** 根据详情创建初始的审批表单数据 */
export const createInitialApprovalFormValues = (): TApprovalFormValues => {
  const values: TApprovalFormValues = {
    receivedAmount: undefined,
    feeCurrencyType: undefined,
    fee: undefined,
    receivedDate: undefined,
    receivedBankStatement: undefined,
    receivedExchangeRate: undefined,
    actualRechargeAmount: undefined,
  }
  return values
}
