import axios, { AxiosRequestConfig } from 'axios'

import { ISuccessAndFailResultModalProps } from '../../components/SuccessAndFailResultModal'
import { IReqData, IRes } from './interface'
import './__mock__'

/** 获取主列表 */
export const apiGetRecords = async (params: IReqData, signal?: AxiosRequestConfig['signal']): Promise<IRes> => {
  return axios.get(`/linkhub_oms/user/account/bank/statement`, { params, signal }).then(res => res.$data)
}

/** Excel导入（仅账务侧） */
export const apiImport = async (file: File): Promise<ISuccessAndFailResultModalProps['result']> => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(`/linkhub_oms/user/account/bank/statement/excel`, formData).then(res => res.$data)
}
