import axios, { AxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'

import { IDetail, IReqData, IRes } from './interface'
import './__mock__'

/** 获取主列表 */
export const apiGetRecords = async (params: IReqData, signal?: AxiosRequestConfig['signal']): Promise<IRes> => {
  return axios.get(`/linkhub_oms/Invoice/list`, { params, signal }).then(res => res.$data)
}

/** 获取详情 */
export const apiGetDetail = async (invoiceId: string, merchantUserId: string, $hideErrMsg?: boolean) => {
  return axios
    .get(`/linkhub_oms/Invoice/generate_invoice`, { params: { invoiceId, merchantUserId }, $hideErrMsg })
    .then(res => {
      const data = res.$data as IDetail
      const { startDate, endDate } = data
      const $billingPeriod = `${startDate ? dayjs(startDate).format($F_YMD) : '--'} ~ ${endDate ? dayjs(endDate).format($F_YMD) : '--'}`

      return {
        ...data,
        /** 计费周期 */
        $billingPeriod,
      }
    })
}
