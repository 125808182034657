import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import { Modal } from 'antd'

import { CusTable } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { apiGetReceivBankRecords } from '../apis'
import { popupSlot } from '../utils'

const updatePopup = popupSlot.insert(null)

export const ReceivedBankModal = Object.assign(
  ({ destroy }: { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)

    const { loading, data } = useRequest(apiGetReceivBankRecords)

    return (
      <Modal
        title={t('Transaction.zhi-chi-yi-xia-yin-hang-ka-zhuan-zhang-chong-zhi')}
        width={900}
        open={open}
        onCancel={onHide}
        afterOpenChange={afterOpenChange}
        cancelText={t('2-common.guan-bi')}
        footer={(_originNode, { CancelBtn }) => <CancelBtn />}
      >
        <CusTable
          style={{ minHeight: 200 }}
          rowKey="id"
          loading={!data && { spinning: loading, delay: SPIN_DELAY }}
          dataSource={data}
          columns={[
            {
              title: t('Transaction.yin-hang-ming-cheng'),
              render: (_v, { bankName: v }) => v,
            },
            {
              title: t('Transaction.yin-hang-zhang-hao'),
              render: (_v, { cardNumber: v }) => v,
            },
            {
              title: t('Transaction.yin-hang-zhang-hu-ming'),
              render: (_v, { account: v }) => v,
            },
            {
              title: t('Transaction.zhang-hu-di-zhi'),
              render: (_v, { accountAddress: v }) => v,
            },
            {
              title: t('Transaction.kai-hu-hang-di-zhi'),
              render: (_v, { bankAddress: v }) => v,
            },
            {
              title: t('Transaction.kai-hu-hang-hao'),
              render: (_v, { bankCode: v }) => v,
            },
          ]}
        />
      </Modal>
    )
  },
  {
    open: () => {
      updatePopup(<ReceivedBankModal destroy={() => updatePopup(null)} />)
    },
  },
)
