import { createContext } from 'use-context-selector'
import { FormInstance } from 'antd'

import { IDetail, TApprovalFormValues, TFormValues } from '../interface'

export interface ICtxVal {
  /** view-查看; approval-审批; submit-提交; resubmit-重新提交; */
  mode: 'view' | 'approval' | 'submit' | 'resubmit'
  /** 主表单实例 */
  mainForm: null | FormInstance<TFormValues>
  /** 设置主表单实例 */
  setMainForm: (form: FormInstance<TFormValues>) => void
  /** 审批表单实例 */
  approvalForm: null | FormInstance<TApprovalFormValues>
  /** 设置审批表单实例 */
  setApprovalForm: (form: FormInstance<TApprovalFormValues>) => void
  /** 按钮禁用 */
  btnDisabled: boolean
  /** 设置按钮禁用 */
  setBtnDisabled: (val: boolean) => void
  /** 详情 */
  detail?: IDetail
  /** 商家用户id */
  merchantUserId?: string
  /** 需要填充的数据 */
  fillData?: Pick<IDetail, 'merchantUserId' | 'payBankName' | 'payAccount' | 'payCardNumber'>
}

export const Ctx = createContext<ICtxVal>({
  mode: 'view',
  mainForm: null,
  setMainForm: () => undefined,
  approvalForm: null,
  setApprovalForm: () => undefined,
  btnDisabled: false,
  setBtnDisabled: () => undefined,
})
